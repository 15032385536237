<dx-text-box
	validationMessageMode="always"
	#autocomplete
	[stylingMode]="stylingMode"
	[labelMode]="labelMode"
	[(value)]="autoCompleteValue"
	label="{{ label }}"
	(onValueChanged)="handleValueChange($event)"
	valueChangeEvent="keyup"
	[inputAttr]="{ id: id }"
>
	<ng-container *ngIf="isRequired">
		<dx-validator>
			<dxi-validation-rule type="required" message="{{ requiredMessage }}"> </dxi-validation-rule>
		</dx-validator>
	</ng-container>
</dx-text-box>
