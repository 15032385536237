<ngx-spinner *ngIf="true" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-spin-fade" [fullScreen]="false">
	<p style="color: white">Loading...</p>
</ngx-spinner>
<!-- <div style="display: flex; justify-content: space-between; align-items: center; margin-top: 20px" *ngIf="fileName != ''">
	<p>{{ fileLabel }}</p>
	<button mat-icon-button aria-label="close dialog" mat-dialog-close style="margin-top: -20px" (click)="onCancel($event)">
		<mat-icon>close</mat-icon>
	</button>
</div> -->
<div style="height: 90%">
	<div #fileView style="width: 100%; height: 100%"></div>
	<div style="display: flex; flex-direction: row; justify-content: center; align-items: center">
		<div style="padding-top: 20px; padding-bottom: 10px; text-align: center; display: flex; flex-direction: column; align-items: center">
			<button mat-raised-button color="primary" style="font-size: 15px; max-width: 250px; text-align: center" (click)="onAcknowledgeFile($event)">Approve</button>
		</div>
		<div style="padding-top: 10px; text-align: center; padding-left: 15px">
			<button mat-button style="border: 2px solid; border-radius: 10px; background: transparent; font-size: 15px; min-width: 100px; text-align: center" (click)="onCancel($event)">Cancel</button>
		</div>
	</div>
</div>
