<app-client-header-logged-out *ngIf="headerService.headerType === 'clientHeaderLoggedOut'"> </app-client-header-logged-out>
<app-client-header-logo-only *ngIf="headerService.headerType === 'logoOnly'"></app-client-header-logo-only>
<!-- <app-client-header *ngIf="headerService.headerType === 'clientHeader'"></app-client-header> -->
<app-loan-application-header [headerText]="headerService.headerText" *ngIf="headerService.headerType === 'clientApplicationHeader'"></app-loan-application-header>

<!-- <p-sidebar [(visible)]="helpMenuService.visibility" position="right">
	<div [innerHTML]="helpMenuService.content"></div>
</p-sidebar> -->
<br />
<br />
<router-outlet></router-outlet>
